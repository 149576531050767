'use client';

import Link from 'next/link';
import { IPopulars } from '@/types/name';
import { useCallback, useState } from 'react';
import Container from '@/src/components/ui/Container';
import clsx from 'clsx';

const typeMapping: Record<keyof IPopulars, string> = {
    brands: 'brand',
    categories: 'tag',
    shops: 'shop',
    series: 'series',
};

const PopularTags = ({ populars }: { populars: IPopulars }) => {
    const [selected, setSelected] = useState<keyof IPopulars>('brands');

    const handleClick = useCallback((type: keyof IPopulars) => {
        setSelected(type);
    }, []);

    return (
        <div>
            <Container>
                <div className="flex gap-15 md:overflow-x-auto md:no-scrollbar">
                    {Object.entries(populars).map(([type, { title }], i) => (
                        <div
                            key={`${title}_${i}`}
                            className="flex gap-4 uppercase"
                        >
                            <button
                                onClick={() =>
                                    handleClick(type as keyof IPopulars)
                                }
                                className={clsx(
                                    'pb-8 pt-16 text-center capitalize whitespace-nowrap md:text-sm',
                                    selected === type
                                        ? 'text-white border-b-4 border-primary'
                                        : 'text-lightGray',
                                )}
                            >
                                {title}
                            </button>
                        </div>
                    ))}
                </div>
            </Container>
            <div className="bg-footerBg/5 py-36">
                <Container>
                    <div className="grid grid-flow-col grid-rows-5 gap-20 md:gap-15">
                        {Object.keys(populars).map(type =>
                            populars[type as keyof IPopulars].items.map(
                                ({ id, title, slug }) => (
                                    <Link
                                        key={id}
                                        className={clsx(
                                            'hover:font-semibold md:text-sm',
                                            selected !== type ? 'hidden' : '',
                                        )}
                                        href={`/${
                                            typeMapping[type as keyof IPopulars]
                                        }/${slug}`}
                                    >
                                        {title}
                                    </Link>
                                ),
                            ),
                        )}
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default PopularTags;
