export interface CountdownTimeLeft {
    dager?: number;
    timer?: number;
    minutter?: number;
    sekunder?: number;
}

const INITIAL_TIME_LEFT: CountdownTimeLeft = {
    dager: 0,
    timer: 0,
    minutter: 0,
    sekunder: 0,
};

export const calculateTimeLeft = (deadline: Date): CountdownTimeLeft => {
    const currentDate = new Date();
    const difference = deadline.getTime() - currentDate.getTime();
    if (difference > 0) {
        return {
            dager: Math.floor(difference / (1000 * 60 * 60 * 24)),
            timer: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutter: Math.floor((difference / 1000 / 60) % 60),
            sekunder: Math.floor((difference / 1000) % 60),
        };
    }

    return INITIAL_TIME_LEFT;
};
