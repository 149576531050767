'use client';

import React, { useEffect, useState } from 'react';
import { addZero } from './utils/addzero';
import {
    calculateTimeLeft,
    CountdownTimeLeft,
} from './utils/calculateTimeLeft';

const countdownDate = new Date('2024-11-29T00:00:00+01:00');

const Counter = ({ customClasses }: { customClasses?: string }) => {
    const [timeLeft, setTimeLeft] = useState<CountdownTimeLeft>(() =>
        calculateTimeLeft(countdownDate),
    );

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(countdownDate));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div
            className={`absolute w-3/10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 m-auto text-dark grid grid-cols-4 gap-20 lg:gap-12 md:gap-3  ${
                customClasses || ''
            }`}
        >
            {Object.entries(timeLeft).map(([unit, value]) => (
                <div
                    key={unit}
                    className="flex flex-col gap-y-4 items-center relative grow sm:gap-y-2"
                >
                    <p className="uppercase text-18 font-bold xl:text-14 lg:text-10 md:text-6 sm:capitalize">
                        {unit}
                    </p>
                    <div className="h-80 bg-white font-hind -skew-x-35 flex items-center justify-center origin-top w-full xl:h-60 lg:h-40 md:h-20">
                        <p className="text-56 font-bold skew-x-35 xl:text-4xl lg:text-2xl md:text-sm">
                            {addZero(value)}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Counter;
