import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/hind/hind-regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/hind/hind-medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/hind/hind-bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/hind/hind-semibold.woff2\",\"weight\":\"600\",\"style\":\"normal\"}],\"variable\":\"--font-hind\"}],\"variableName\":\"hind\"}");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/noland/noland-regular.woff2\",\"weight\":\"100 1000\",\"style\":\"normal\"}],\"variable\":\"--font-noland\"}],\"variableName\":\"noland\"}");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/public/bg.jpg");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/src/components/elements/Counter/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/virtual/deployer/www/black-friday-norge.no/src/components/elements/Navigation/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/virtual/deployer/www/black-friday-norge.no/src/components/elements/PopularTags/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/virtual/deployer/www/black-friday-norge.no/src/globals.scss");
