'use client';

import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

const links = {
    produkter: '/',
    artikler: '/articles',
    butikker: '/shops',
};

const Navigation = () => {
    const pathname = usePathname();

    return (
        <nav className="flex gap-30 items-center md:gap-20">
            {Object.entries(links).map(([name, href]) => (
                <Link
                    key={href}
                    href={href}
                    className={clsx(
                        'font-noland text-center text-xl capitalize md:text-sm border-b-4 pb-10 md:pb-5',
                        pathname === href
                            ? 'border-primary'
                            : 'border-transparent',
                    )}
                >
                    {name}
                </Link>
            ))}
        </nav>
    );
};

export default Navigation;
